import { getIconAll } from "@lib";
import { saveAppMenu, addAppMenu } from "@lib/apply-menu";
export default {
  components: {},
  data() {
    return {
      ruleForm: {
        id: '',
        appId: "",
        fullName: "",
        name: "",
        sort: "",
        iconId: "",
        menuShow: 0,
        remark: "",
        url: "",
        pid: "",
        moduleId:''
      },
      disabled: false,
      platArr: [],
      categoryArr: [],
      tit: "",
      Files: [],
      resId: "",
      dialogShow: false,
      iconArr: [],
      actIconInfo: "",
      rules: {
        fullName: [
          { required: true, message: "请填写应用名称", trigger: "change" },
        ],
        name: [
          {
            required: true,
            message: "请填写菜单名称",
            trigger: ["change", "blur"],
          },
        ],
        id: [
          {
            required: true,
            message: "请填写功能编码",
            trigger: ["change", "blur"],
          },
        ],
        // iconId: [
        //   {
        //     required: true,
        //     message: "请选择图标",
        //     trigger: ["change", "blur"],
        //   },
        // ],
        // remark: [
        //   {
        //     required: true,
        //     message: "请填写备注",
        //     trigger: ["change", "blur"],
        //   },
        // ],
        // url: [
        //   { required: true, message: "菜单URL", trigger: ["change", "blur"] },
        // ],
      },
      editOrSee: "",
      cascader: false, //是否禁用级联
      options: [], //级联选择器
    };
  },
  watch:{
    "ruleForm.url"(n,o){
      // const editOrSee = this.editOrSee;
      if(!n && o){
        this.ruleForm.moduleId = '';
      }
    }
  },
  async created() {
    this.initPagesInfo();
    await getIconAll().then((res) => {
      this.iconArr = res.result;
      this.actIconInfo = res.result[0];

      this.ruleForm.iconId = this.actIconInfo.id;
      this.ruleForm.menuIcon = this.actIconInfo.content;
    });
    let { info } = this.$route.params;
    this.childrenId = [];

    if (info != undefined) {
      this.$nextTick(() => {
        const look = JSON.parse(info);
        this.getChirend(look.children);
        this.ruleForm = {
          ...this.ruleForm,
          ...look,
          pid: look.p.length > 0 ? look.p : "",
        };
        this.actIconInfo = {
          iconDom: `<i class="iconfont">${this.ruleForm.menuIcon}</i>`,
        };
      });
    } else {
      this.disabled = false;
    }
  },
  methods: {
    //获取子孩子
    getChirend(list) {
      if (!list) return;
      const children = this.childrenId;
      for (let index = 0; index < list.length; index++) {
        const c = list[index];
        if (c.children) this.getChirend(c.children);
        children.push(c.id);
      }
      this.childrenId = [...new Set([...this.childrenId, ...children])];
    },
    //判断是否为自己或者下级
    isMeOrChildren() {
      const { ruleForm } = this;
      console.log(ruleForm.pid)
      if (
        ruleForm.pid &&
        ruleForm.pid[ruleForm.pid.length - 1] === ruleForm.id
      ) {
        this.$message.warning("不能选择自己做为上级");
        return true;
      }
      // 是否是儿子
      if (
        ruleForm.pid &&
        this.childrenId.indexOf(ruleForm.pid[ruleForm.pid.length - 1]) !== -1
      ) {
        this.$message.warning("不能选择下级做为上级");
        return true;
      }

      return false;
    },
    //   确认表单信息
    submitForm(formName) {
      const { ruleForm, editOrSee } = this;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.isMeOrChildren()) return false;
          const data = {
            ...ruleForm,
            pid: ruleForm.pid && ruleForm.pid.length ? ruleForm.pid[ruleForm.pid.length - 1] : "ROOT",
          };
          let res;
          if (!editOrSee) {
            res = await addAppMenu(data)
          } else {
            res = await saveAppMenu(data);
          }

          const { status } = res;
          if (status === "success") {
            this.$message.success(`${editOrSee == 1 ? "修改" : "新增"}成功！`);
            this.$router.back();
          }
        }
      });
    },
    choiceFn(item) {
      this.actIconInfo = item;
      this.ruleForm.iconId = item.id;
      this.ruleForm.menuIcon = item.content;
      this.dialogShow = false;
    },

    //初始化页面信息
    initPagesInfo() {
      this.editOrSee = this.$route.params.editOrSee;
      if (this.editOrSee == 0) {
        this.disabled = true;
      }
      const info = sessionStorage.getItem("applyMenu");
      // 上级菜单
      const superior = sessionStorage.getItem("applyMenuAdd");
      const { code, fullName } = JSON.parse(info);
      this.ruleForm.appId = code;
      this.ruleForm.fullName = fullName;
      this.options = JSON.parse(superior) || [];

      if (this.options.length) {
        this.rules = {
          ...this.rules,
          // pid: [
          //   {
          //     required: true,
          //     message: "请选择上级菜单",
          //     trigger: ["change", "blur"],
          //   },
          // ],
        };
      } else {
        this.cascader = true;
      }
    },
  },
  mounted() { },
};
